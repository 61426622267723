<template>
	<div class="kpi">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>绿色行动计划重要指标</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>指标监控</li>
						<li>/</li>
						<li>关键指标</li>
					</ul>
				</div>
			</div>
			<div class="robin_circle" style="margin: 6px 0px 18px 20px;">
				<div class="r_out">
					<div class="r_in">
						<div class="r_c c1"></div>
						<div class="r_num">45,685</div>
					</div>
					<p>总能耗(kW·h)</p>
					<br />
					<div class="alert alert-success alert-lost">
						实际值：45685
					</div>
					<div class="alert alert-warning alert-lost">
						计划值：65264
					</div>
				</div>
				<div class="r_out">
					<div class="r_in">
						<div class="r_c c2"></div>
						<div class="r_num">20843.67</div>
					</div>
					<p>办公楼(kW·h)</p>
					<br />
					<div class="alert alert-success alert-lost">
						实际值：20843
					</div>
					<div class="alert alert-warning alert-lost">
						计划值：41687
					</div>
				</div>
				<div class="r_out">
					<div class="r_in">
						<div class="r_c c3"></div>
						<div class="r_num">10208.5</div>
					</div>
					<p>营业厅(kW·h)</p>
					<br />
					<div class="alert alert-success alert-lost">
						实际值：10208
					</div>
					<div class="alert alert-warning alert-lost">
						计划值：30934
					</div>
				</div>
				<div class="r_out">
					<div class="r_in">
						<div class="r_c c4"></div>
						<div class="r_num">8608.35</div>
					</div>
					<p>机房(kW·h)</p>
					<br />
					<div class="alert alert-success alert-lost">
						实际值：8608
					</div>
					<div class="alert alert-warning alert-lost">
						计划值：35078
					</div>
				</div>
				<div class="r_out">
					<div class="r_in">
						<div class="r_c c5"></div>
						<div class="r_num">6024.48</div>
					</div>
					<p>基站(kW·h)</p>
					<br />
					<div class="alert alert-success alert-lost">
						实际值：6024
					</div>
					<div class="alert alert-warning alert-lost">
						计划值：46342
					</div>
				</div>
			</div>
			<hr class="whiter" />
			<div class="block-area">
				<div class="top">
					<ul>
						<li class="active" style="width:25%">
							•单位信息流量综合能耗
						</li>
						<li style="width:25%">
							<span>•单位电信业务总量综合能耗</span>
						</li>
						<li style="width:25%">
							<span>•基站每载频耗电</span>
						</li>
						<li style="width:25%">
							•百元固定资产原值电费
						</li>
					</ul>
				</div>
				<div class="tile">
					<div class="tile-left">
						<ul>
							<li>周视图</li>
							<li>月视图</li>
							<li>年视图</li>
						</ul>
					</div>
					<div class="tile-content"></div>
					<div class="tile-right">
						<div class="title"><h2>阶段平均统计</h2></div>
						<div style="margin:20px">
							<span>综合能耗</span><span>250万千克标准煤</span>
						</div>
						<div style="margin:20px">
							<span>信息流量</span><span>251TB</span>
						</div>
						<div style="margin:20px">
							<span>单位平均</span><span>1千克标准煤/TB</span>
						</div>
						<div style="margin:20px">
							<span>同比</span><span class="count">↓ 10%</span>
						</div>
					</div>
				</div>
				<h4 class="page-title">能耗概览</h4>
				<div class="item">
					<div class="box">
						<div class="box-title">
							<span>核心机房PUE</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>营业厅能耗</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>基站能耗</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>能耗等级</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content">
							<div style="padding-top:50px;padding-right:50px;" id="cur">
								<div
									style="width:60px; height:30px;background:#006A3C;border-radius:15px;float:right"
								>
									<div class="triangle-left" style="float:right;"></div>
								</div>
								<span style="float:right">1级</span>
								<div style="clear:both"></div>
								<span style="float:right">当前</span>
							</div>
							<ul class="nav" style="padding-top:30px;padding-left:50px;">
								<li class="active">
									<span style="float:left;padding-left:30px;">耗能低</span>
									<div
										style="width:60px; height:30px;background:#006A3C;border-radius:15px;float:left"
									></div>
									<div
										id="triangle-right"
										style="border-left: 20px solid #006A3C;float:left"
									></div>
									<span>1级</span>
								</li>
								<li>
									<div style="clear:both"></div>
									<br />
									<span style="float:left;padding-left:30px">中等 </span>
									<div
										style="width:80px; height:30px;background:#8EC73C;border-radius:15px;float:left;"
									></div>
									<div
										id="triangle-right"
										style="border-left: 20px solid #8EC73C;float:left"
									></div>
									<span>2级</span>
								</li>
								<li>
									<div style="clear:both"></div>
									<br />
									<span style="float:left;padding-left:30px">耗能高</span>
									<div
										style="width:100px; height:30px;background:#DB241C;border-radius:25px;float:left;"
									></div>
									<div
										id="triangle-right"
										style="border-left: 20px solid #DB241C;float:left"
									></div>
									<span>3级</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="box">
						<div class="box-title">
							<span>核心机房PUE</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>营业厅能耗</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>基站能耗</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
					<div class="box">
						<div class="box-title">
							<span>能耗等级</span>
							<span><i class="el-icon-more"></i></span>
						</div>
						<div class="box-content"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
export default {
	components: {
		sidebar,
		headerTop,
	},
}
</script>

<style lang="scss" scoped>
.kpi {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:nth-child(5) {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.robin_circle {
			.r_out {
				width: 120px;
				height: 120px;
				border: 2px solid #d9d9d9;
				background: #fff;
				box-shadow: 3px 3px 5px #bfbfbf;
				border-radius: 50%;
				display: inline-block;
				margin-right: 90px;
				position: relative;
				.r_in {
					width: 120px;
					height: 120px;
					border: 10px solid #fff;
					border-radius: 50%;
					overflow: hidden;
					position: relative;
					.r_c {
						width: 120px;
						height: 120px;
						position: absolute;
						bottom: 0;
						left: 0;
						height: 0;
					}
					.c1 {
						background: #fbad4c;
					}
					.r_num {
						color: #404040;
						font-size: 23px;
						line-height: 1.5;
						font-weight: bold;
						position: absolute;
						top: 50%;
						margin-top: -25px;
						text-align: center;
						width: 100%;
					}
				}
				p {
					position: absolute;
					color: #fff;
					text-align: center;
					margin: 0 auto;
					width: 100%;
					font-size: 14px;
					line-height: 1.5;
					font-weight: bold;
				}
				.alert-lost {
					padding: 5px;
					margin-top: 10px;
					margin-bottom: -8px;
					font-size: 14px;
				}
				.alert-success {
					color: #069727;
					border-left: 5px solid #048f23;
				}
				.alert-warning {
					color: #a78c00;
					border-left: 5px solid #a78c00;
				}
				.alert {
					background: rgba(255, 255, 255, 0.7);
					font-family: open-sans-regular;
					font-size: 14px;
					border-radius: 4px;
				}
			}
		}
		.whiter {
			border-top: 1px solid rgba(255, 255, 255, 0.65);
		}
		.block-area {
			.top {
				ul {
					display: flex;
					padding: 0;
					li {
						padding: 5px 10px 6px;
						flex: 1;
						cursor: pointer;
						color: #fff;
						background: rgba(0, 0, 0, 0.35);
						font-size: 14px;
					}
					li:hover {
						color: #ffd600;
					}
				}
			}
			.tile {
				display: flex;
				height: 350px;
				margin-top: 10px;
				margin-bottom: 20px;
				.tile-left {
					flex: 0.8;
					background: #e64c65;
					margin: 30px 10px;
					height: 300px;
					ul {
						height: 100%;
						padding: 0;
						display: flex;
						flex-direction: column;
						li {
							flex: 1;
							width: 100%;
							text-align: center;
							line-height: 100px;
							color: #fff;
							cursor: pointer;
						}
						li:hover {
							background: rgba(0, 0, 0, 0.15);
							color: #ffd600;
						}
					}
				}
				.tile-content {
					flex: 6;
				}
				.tile-right {
					flex: 3;
					background: #e64c65;
					margin: 30px 10px;
					height: 300px;
					color: #fff;
					font-size: 14px;
					.title {
						padding-top: 50px;
						text-align: center;
					}
					span {
						padding: 10px;
					}
					.count {
						padding-left: 40px;
					}
				}
			}
			.page-title {
				padding: 8px 15px 9px;
				font-size: 15px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				margin: 0;
			}
			.item {
				display: flex;
				margin-bottom: 20px;
				.box {
					flex: 1;
					color: #fff;
					font-size: 14px;
					margin: 0 10px;
					.box-title {
						display: flex;
						justify-content: space-between;
						padding: 5px;
						background: rgba(0, 0, 0, 0.65);
					}
					.box-content {
						height: 300px;
						background: rgba(0, 0, 0, 0.35);
						#triangle-right {
							width: 0;
							height: 0;
							border-top: 15px solid transparent;
							border-left: 15px solid #db241c;
							border-bottom: 15px solid transparent;
						}
					}
				}
			}
		}
	}
}
</style>
